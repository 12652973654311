@import url(https://fonts.googleapis.com/css2?family=Oldenburg&display=swap);
* {
  font-family: 'Oldenburg', cursive;
   background-color: '#fff4e6';
}
body {
  background-image: url(/static/media/pattern.dcf53793.png);
  background-repeat: no-repeat;
  /* object-fit: cover; */

}


/* Scrollbar */

/* Firefox */
* {
  scrollbar-color: #3c2f2f #fff4e6;
  scrollbar-width: thin;
}

::-webkit-scrollbar-track {
  background: #854442 !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-image: linear-gradient(to right bottom, #3c2f2f, #fff4e6);
}
::-webkit-scrollbar {
  width: 8px;
  height: 0px;
  /* height 0:  hide the horizontal scrollbar */
}
::-webkit-scrollbar-corner {
  background: #854442 !important;
}


